import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import useBlog from "hooks/useBlog";
import { t } from "utils/translate";

import "styles/blog-page.scss";
import "styles/product-card.scss";

export const blogQuery = graphql`
  query BlogQuery {
  allContentfulBlogPost {
    nodes {
      blogId
      type
      title
      image {
        title
        file {
          url
          contentType
        }
        gatsbyImageData(
          placeholder: NONE,
          formats: [AUTO, WEBP, AVIF],
        )
      }
      primaryButton {
        href
        text
        type
      }
      secondaryButton {
        href
        text
        type
      }
      productLink {
        href
        id
        name
        text
      }
    }
  }
}
`;

const SingleBlog = (props) => {
    const { blogData, products = [] } = props;

    const queryData = useStaticQuery(blogQuery);
    const { formattedDate } = useBlog({ queryData, blogData, products });

    return (
        <div className="single-blog">
            <div className="single-blog__content">
                <h1 className="single-blog__content--title">
                    {blogData.title}
                </h1>
                <div className="single-blog__author">
                    <p className="typography__small">
                        {t('Written by')}{' '}{blogData.author}
                    </p>
                    <p className="typography__small">
                        {t('Published')}{' '}{formattedDate(blogData.published_date.date)}
                    </p>
                </div>
                <div className="single-blog__content--pagebuilder"
                     dangerouslySetInnerHTML={{ __html: blogData.body }}
                />
            </div>
        </div>
    );
};
export default SingleBlog;
