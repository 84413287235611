import React, { useEffect } from "react";
import { Link } from "gatsby";
import ReactDOM from "react-dom";
import { isNotEmpty } from "utils/helper";

import { useWindow } from "context/windowContext";
import CurrencyProvider from "context/currencyContext";

import Button from "components/UI/button";
import ProductSlider from "components/UI/productSlider";
import Image from "components/UI/image";


const useBlog = (props) => {
    const { blogData, queryData, products } = props;
    const { document } = useWindow();

    const formattedDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString("en-US");
    };

    const getBannerLayout = (content) => (
        <div className="blog-banner">
            <div className="blog-banner__laptop">
                <div className="blog-banner__main">
                    {isNotEmpty(content.productLink) &&
                        (<Link to={content.productLink.href} className="blog-banner__main__subtitle">
                            {content.productLink.text}
                        </Link>)
                    }
                    <h2 className="blog-banner__main__title">{content.title}</h2>
                    {content.primaryButton && (
                        <Button
                            value={content.primaryButton.text}
                            type={content.primaryButton.type}
                            href={content.primaryButton.href}
                        />
                    )}
                    {content.secondaryButton && (
                        <Button
                            value={content.secondaryButton.text}
                            type={content.secondaryButton.type}
                            href={content.secondaryButton.href}
                        />
                    )}
                </div>
                <Image image={content.image} className="blog-banner__image"/>
            </div>
            <div className="blog-banner__tablet">
                <div className="blog-banner__main">
                    <div className="blog-banner__main--wrapper">
                        <div>
                            {isNotEmpty(content.productLink) &&
                                (<Link to={content.productLink.href} className="blog-banner__main__subtitle">
                                    {content.productLink.text}
                                </Link>)
                            }
                            <h2 className="blog-banner__main__title">{content.title}</h2>
                        </div>
                        <Image image={content.image} className="blog-banner__image"/>
                    </div>
                    {content.primaryButton && (
                        <Button
                            value={content.primaryButton.text}
                            type={content.primaryButton.type}
                            href={content.primaryButton.href}
                        />
                    )}
                    {content.secondaryButton && (
                        <Button
                            value={content.secondaryButton.text}
                            type={content.secondaryButton.type}
                            href={content.secondaryButton.href}
                        />
                    )}
                </div>
            </div>
            <div className="blog-banner__main blog-banner__mobile">
                <div className="blog-banner__main--wrapper">
                    <div>
                        {isNotEmpty(content.productLink) &&
                            (<Link to={content.productLink.href} className="blog-banner__main__subtitle">
                                {content.productLink.text}
                            </Link>)
                        }
                        <h2 className="blog-banner__main__title">{content.title}</h2>
                    </div>
                    <Image image={content.image.file.url} className="blog-banner__image"/>
                </div>
                {content.primaryButton && (
                    <Button
                        value={content.primaryButton.text}
                        type={content.primaryButton.type}
                        href={content.primaryButton.href}
                    />
                )}
                {content.secondaryButton && (
                    <Button
                        value={content.secondaryButton.text}
                        type={content.secondaryButton.type}
                        href={content.secondaryButton.href}
                    />
                )}
            </div>
        </div>
    );

    useEffect(() => {
        const sliderBreakpoints = {
            320: {
                slidesPerView: 1.2,
                spaceBetween: 10
            },
            425: {
                slidesPerView: 1.7,
                spaceBetween: 10
            },
            768: {
                slidesPerView: 2.2,
                spaceBetween: 20
            },
            1024: {
                slidesPerView: 2.7,
                spaceBetween: 20
            },
            1440: {
                slidesPerView: 2.2,
                spaceBetween: 20
            }
        };

        const contentfulElements = document.querySelectorAll('[data-contentful-id]');
        isNotEmpty(contentfulElements) && contentfulElements
            .forEach(element => {
                const { contentfulId } = element.dataset;

                const currentBlogData = queryData.allContentfulBlogPost.nodes.find(
                    el => el.type === contentfulId
                );

                currentBlogData && ReactDOM.render(getBannerLayout(currentBlogData), element);
            })

        const sliderElements = document.querySelectorAll('[data-slider-skus]');
        isNotEmpty(sliderElements) && sliderElements
            .forEach(element => {
                const { sliderSkus, sliderTitle } = element.dataset;
                const sliderSkusArray = sliderSkus
                    .replaceAll(" ", "")
                    .split(",");

                const sliderProducts = products.filter(product => sliderSkusArray.includes(product.sku));

                isNotEmpty(sliderProducts) && ReactDOM.render((
                    <CurrencyProvider>
                        <ProductSlider
                            title={sliderTitle}
                            products={sliderProducts}
                            breakpoints={sliderBreakpoints}
                            isHomepage
                            isHideButtons
                        />
                    </CurrencyProvider>
                ), element);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blogData]);

    return {
        formattedDate,
    };
};

export default useBlog;
